// TODO: investigar por que o ESLint no VSCode aponta erro nessa linha mas o ESLint no terminal não e ao corrigir o problema para o VSCode, o ESLint no terminal aponta erro.

import type { FunctionalComponent, SVGAttributes } from '#app/compat/capi';
import type {
	Property,
	DisplayAddress,
	PropertyImage
} from '@SHARED/core/entities/Property';
import type { PresenterLabel } from '@SHARED/presenters/LabelPresenter';

import { PLACEHOLDER_IMAGE_URL } from '@SHARED/presenters/LabelPresenter';
import { grammarInflection, formatCurrency } from '@SHARED/utils';

import AreaIcon from '~icons/pilar/area';
import SuitesIcon from '~icons/pilar/suites';
import BathroomsIcon from '~icons/pilar/bathrooms';
import BedroomsIcon from '~icons/pilar/bedrooms';
import ParkingSpotsIcon from '~icons/pilar/parking-spots';
import TotalAreaIcon from '~icons/pilar/total-area';

export type IconComponent = FunctionalComponent<SVGAttributes, {}, any>;

export type PropertyItemInformation =
	| 'suites'
	| 'bedrooms'
	| 'parkingSpots'
	| 'bathrooms'
	| 'totalArea'
	| 'area'
	| 'condoFee'
	| 'tax'
	| 'pricePerSquareMeter';

export class PropertyPresenter {
	static getAddressText({ ad, address, number, region }: Property): string {
		const displayAddressOption = ad.displayAddress;

		const addressOptions = {
			region: `${region}`,
			street: `${address} - ${region}`,
			full: `${address}, ${number} - ${region}`
		} as Record<DisplayAddress, string>;

		if (!address) return addressOptions.region;
		if (!number) return addressOptions.street;

		return addressOptions[displayAddressOption];
	}

	static getCurrencyInfoText(
		number: number | null,
		includeCents = false
	): string {
		return number === null ? '' : formatCurrency(number, includeCents);
	}

	static getPropertyPricingText(
		{ askingPrice, rentPrice }: Property,
		includeCents = false
	): string {
		const askingPriceText = askingPrice
			? this.getCurrencyInfoText(askingPrice, includeCents)
			: null;

		const rentPriceText = rentPrice
			? `${this.getCurrencyInfoText(rentPrice, includeCents)}/mês`
			: null;

		return askingPriceText && rentPriceText
			? `${askingPriceText} ou ${rentPriceText}`
			: askingPriceText || rentPriceText || '';
	}

	static getItemsInformation(
		items: PropertyItemInformation[],
		property: Property,
		labelStyling: 'simplified' | 'complete' = 'complete'
	): PresenterLabel[] {
		const propertyType = property.propertyType.name.toLowerCase();

		const areaLabelVariants: [[string], [string]] =
			labelStyling === 'simplified'
				? [['Construídos'], ['úteis']]
				: [['Área construída'], ['Área útil']];

		const areaLabels = /casa|terreno/.test(propertyType)
			? areaLabelVariants[0]
			: areaLabelVariants[1];

		const totalAreaLabels =
			labelStyling === 'simplified' ? ['Totais'] : ['Área total'];

		const itemsDictionary = {
			suites: {
				labels: ['Suíte', 'Suítes'],
				icon: SuitesIcon
			},
			bedrooms: {
				labels: ['Dormitório', 'Dormitórios'],
				icon: BedroomsIcon
			},
			parkingSpots: {
				labels: ['Vaga', 'Vagas'],
				icon: ParkingSpotsIcon
			},
			bathrooms: {
				labels: ['Banheiro', 'Banheiros'],
				icon: BathroomsIcon
			},
			totalArea: {
				labels: totalAreaLabels,
				icon: TotalAreaIcon
			},
			area: {
				labels: areaLabels,
				icon: AreaIcon
			},
			condoFee: {
				labels: ['Valor do Condomínio'],
				icon: null
			},
			tax: {
				labels: ['IPTU (mensal)'],
				icon: null
			},
			pricePerSquareMeter: {
				labels: ['Valor do m²'],
				icon: null
			}
		};

		const rooms: PresenterLabel[] = [];

		items.forEach(item => {
			const value =
				item === 'pricePerSquareMeter'
					? this.getPricePerSquareMeter(property)
					: property[item];

			if (!value || (typeof value === 'number' && value <= 0)) return;

			const { labels, icon } = itemsDictionary[item];

			const label =
				labels.length > 1
					? grammarInflection(value, labels[0], labels[1])
					: labels[0];

			const formattedValue = formatItemInfoValue(item, value);

			rooms.push({ label, value: formattedValue, icon });
		});

		return rooms;
	}

	static getThumbnail({ images }: Property): PropertyImage {
		const [firstImage] = images;

		return {
			...firstImage,
			watermarkUrl: firstImage?.watermarkUrl ?? PLACEHOLDER_IMAGE_URL
		};
	}

	static getTags({ isExclusive }: Property): string[] {
		const propertyTags = [];

		if (isExclusive) propertyTags.push('Exclusivo');

		return propertyTags;
	}

	static getPropertyKeywords(property: Property): string {
		const propertyType = property.propertyType.name;
		const propertyLocation = `${property.region} - ${property.city}`;

		const propertyInfos = this.getItemsInformation(
			['bedrooms', 'parkingSpots', 'bathrooms', 'suites', 'area', 'totalArea'],
			property
		);

		const propertyInfoKeywords = propertyInfos.map(
			({ label, value }) =>
				`imóvel com ${value} ${label.toLowerCase()} em ${propertyLocation}`
		);

		const featureKeywords = Object.values(property.featuresByCategory)
			.map(featureCategory =>
				featureCategory.map(({ name: featureName }) => [
					`${propertyType} com ${featureName} em ${propertyLocation}`,
					`${propertyType} com ${featureName}`
				])
			)
			.flat(2);

		const keywords = [
			`imóveis à venda em ${propertyLocation}`,
			`imóveis para alugar em ${propertyLocation}`,
			`imóveis de luxo em ${propertyLocation}`,
			`${propertyType} à venda em ${propertyLocation}`,
			...propertyInfoKeywords,
			...featureKeywords
		];

		return keywords.join(',');
	}

	static getPricePerSquareMeter({
		area,
		askingPrice
	}: Property): number | null {
		if (!askingPrice) return null;

		const pricePerSquareMeter = askingPrice / area;

		return pricePerSquareMeter;
	}

	static getMainPriceValue({
		ad,
		askingPrice,
		rentPrice
	}: Property): number | null {
		if (!askingPrice && !askingPrice) return null;

		const { transactionType } = ad;

		return transactionType.toLowerCase().includes('sell')
			? askingPrice
			: rentPrice;
	}

	static getSEOThumbnail(
		property: Property | null,
		companyLogoUrl: string
	): string {
		if (property?.thumbnailUrl) return property?.thumbnailUrl;
		const firstImage = property?.images[0]?.watermarkUrl;
		return firstImage || companyLogoUrl;
	}

	static getPropertyDataForSeoTexts(
		property: Property,
		companyName: string
	): {
		title: string;
		description: string;
	} {
		const propertyAdTitle = property.ad.title?.trim();

		const titleTextSecondPart = [propertyAdTitle, property.commercialId]
			.filter(Boolean)
			.join(' - ');

		const title = `${companyName} | ${titleTextSecondPart}`;

		const itemsInformations = this.getItemsInformation(
			['suites', 'parkingSpots'],
			property
		);

		const itemsInformationsTexts = itemsInformations.map(
			({ label, value }) => `${value} ${label.toLowerCase()}`
		);

		const descriptionData = [
			property.propertyType.name,
			property.region,
			`${property.area} m²`,
			...itemsInformationsTexts
		];

		const description = descriptionData.filter(Boolean).join(' - ');

		return { title, description };
	}

	static forceSorting(
		properties: Property[],
		sorting: number[] | null = []
	): Property[] {
		if (!sorting?.length) return properties;

		const sortedProperties = sorting
			.map(index => properties[index])
			.filter(Boolean);

		if (properties.length === sortedProperties.length) return sortedProperties;

		const unsortedProperties = properties.filter(
			property => !sortedProperties.includes(property)
		);

		return sortedProperties.concat(unsortedProperties);
	}
}

function formatItemInfoValue(item: PropertyItemInformation, value: number) {
	if (['area', 'totalArea'].includes(item)) return `${value} m²`;

	return ['pricePerSquareMeter', 'tax', 'condoFee'].includes(item)
		? PropertyPresenter.getCurrencyInfoText(value, true)
		: value;
}
